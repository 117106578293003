<template>
  <v-card
    style="height: 100%; border: 0px solid grey"
    flat
    rounded="20"
    outlined
    class="pa-6 ma-16 d-flex flex-column justify-space-between"
  >
    <v-img contain class="my-10" height="100" src="/error.gif"></v-img>

    <v-card-text class="text-center">
      <h3 class="my-6 red--text">
        An error occurred during SmartSize's installation.
      </h3>
      <h1 class="text-h4 font-weight-bold">
        SmartSize needs to be re-installed
      </h1>

      <p class="mt-10 card-text">
        Please delete and reinstall SmartSize. If you still have issues, contact
        our support team for assistance.
      </p>
      <p class="card-text">
        We're confident that you will be using the app in no time.
      </p>
    </v-card-text>

    <v-card-action>
      <div class="text-center">
        <a href="mailto:team@smartsize.io"
          ><v-btn color="black" text>CONTACT US</v-btn></a
        >
      </div>
    </v-card-action>
  </v-card>
</template>

<script>
export default {
  methods: {
    goNew() {
      this.$router.push({ name: "Templates" });
    },
  },
};
</script>
<style scoped>
.card-text {
  font-size: 16px;
  margin: 10px;
}
</style>
