<template>
  <!-- Icons -->
  <div class="background">
    <InstallError></InstallError>
    <SmartSizeFooter></SmartSizeFooter>
  </div>
  <!-- Icons -->
</template>

<script>
import InstallError from "@/components/Welcome/InstallError.vue";
import SmartSizeFooter from "@/components/GlobalComponents/SmartSizeFooter.vue";

export default {
  data() {
    return {};
  },
  components: {
    InstallError,
    SmartSizeFooter,
  },
};
</script>

<style>
.background {
  min-height: 100vh;
}
</style>